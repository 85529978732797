import React from "react"
import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { ImageBlock } from "../shared/Block/block"

const P = ({ children }) => (
  <div
    style={{
      margin: `0 0 var(--default-margin) 0`,
      padding: `0 0 0 0`,
      //
      fontSize: `1.2rem`,
      lineHeight: `2rem`,
      fontFamily: `nunito sans`,
      //
      textShadow: `0px 0px 8px rgb(0, 0, 0)`,
    }}
  >
    {children}
  </div>
)

const H3 = styled.h3`
  text-shadow: 0px 0px 4px rgb(0, 0, 0.95);
`

const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Viesti vastaanotettu" />
      <ImageBlock
        cover_or_contain="cover"
        background_position="center"
        background_gradient="no-repeat radial-gradient(
          circle at center,
          var(--color-primary-05) 0%,
          var(--color-primary-02) 99%
        )"
        min_height="25rem"
      >
        <H3>Viestisi on vastaanotettu!</H3>
        <P>
          Olen yhteydessä sinuun{" "}
          <span style={{ color: `var(--color-shock-3)` }}>piakkoin</span>,
          <br />
          -Henri
        </P>
      </ImageBlock>
    </Layout>
  </>
)

export default IndexPage
